<template>
  <layout width="420">
    <template slot="header">
      {{ title }}
    </template>
    <template>
      <v-form>
        <template v-if="!edit">
          <v-text-field v-model="state.name" label="Item name">
            {{ state.name }}
          </v-text-field>
          <v-textarea
            v-model="state.description"
            no-resize
            rows="1"
            auto-grow
            label="Description"
          >
            {{ state.description }}
          </v-textarea>
          <v-text-field v-model="state.alertCount" label="Alert count">
            {{ state.alertCount }}
          </v-text-field>
        </template>
        <v-select
          v-model="state.sourceId"
          label="Source name"
          item-value="id"
          item-text="name"
          :items="sources"
          clearable
          @click:clear="clear({ sourceId: null })"
        />
      </v-form>
    </template>
    <template slot="footer">
      <v-spacer />
      <v-btn text color="text-primary" @click.stop="$emit('close')">
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        depressed
        :loading="loading"
        @click.stop="submit"
      >
        Save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import Layout from '@/components/popup/PopupLayoutDefault';
import { ref, onMounted, computed, nextTick } from '@vue/composition-api';
import { usePromise } from 'vue-composable';
import { objectPropertyService, objectService } from '@/modules/common/api';
import { prepareObject } from '@/provider/utils';
import { geoItemService } from '@/modules/object-types/api';
import { objectGeoService } from '@/modules/objects/api';
export default {
  name: 'GeoItemEdit',
  components: {
    Layout
  },
  props: {
    objectId: {
      type: String,
      default: ''
    },
    geoItemId: {
      type: String,
      default: ''
    }
  },
  setup(props, { emit }) {
    const state = ref({
      sourceId: null,
      name: '',
      description: '',
      alertCount: 1
    });
    const sources = ref([]);
    const geoItem = ref({});
    const edit = computed(() => props.geoItemId);
    const title = computed(() => `${edit.value ? 'Edit' : 'Add'} geo item`);

    const handleSubmit = async () => {
      if (!edit.value) {
        const schemaId = await geoItemService.fetchBaseGeoItemSchemaId();

        //create
        const res = await objectService.create({
          name: state.value.name,
          description: state.value.description,
          schemaId
        });
        geoItem.value = prepareObject(res?.data?.createObject?.object);

        //link
        await objectService.link(props.objectId, geoItem.value.id);

        // set properties
        await objectPropertyService.updateValues([
          {
            id: geoItem.value.infoName.id,
            value: state.value.name
          },
          {
            id: geoItem.value.commonAlertCount.id,
            value: Number(state.value.alertCount)
          }
        ]);
      }

      // set properties
      await objectPropertyService.update(geoItem.value.stateSource.id, {
        linkedPropertyId: state.value.sourceId
      });
      emit('close');
    };

    const { loading, exec: submit } = usePromise(handleSubmit, true);

    const clear = obj => {
      nextTick(() => {
        state.value = {
          ...state.value,
          ...obj
        };
      });
    };

    onMounted(async () => {
      const res = await objectGeoService.fetchGeoObjects(props.objectId);
      sources.value = res.map(i => {
        const obj = prepareObject(i);
        return {
          id: obj?.infoOwnId?.id,
          name: obj.name
        };
      });

      if (edit.value) {
        geoItem.value = prepareObject(
          await objectService.fetch(props.geoItemId)
        );

        const linkedPropertyId = geoItem.value.stateSource?.linkedPropertyId;

        if (linkedPropertyId) {
          const source = sources.value.find(
            source => source.id === linkedPropertyId
          );
          if (source) {
            state.value.sourceId = source.id;
          }
        }

        state.value = {
          ...state.value,
          name: geoItem.value.name,
          description: geoItem.value.description || '',
          alertCount: geoItem.value.commonAlertCount.value
        };
      }
    });

    return {
      state,
      sources,
      loading,
      submit,
      clear,
      title,
      edit
    };
  }
};
</script>
